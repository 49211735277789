.app-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

input {
  margin-bottom: 10px;
}

.create-items-button {
  background-color: #6a5acd;
  color: #fff;
  padding: 10px 20px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
}

.error-message,
.success-message {
  margin-top: 10px;
  padding: 10px;
  border-radius: 4px;
  text-align: center;
}

.error-message {
  background-color: #ff6961;
  color: #fff;
}

.success-message {
  background-color: #98fb98;
  color: #000;
}
